<script lang="ts">
    import * as Dialog from '$lib/components/ui/dialog';
    import { Button } from '$lib/components/ui/button';
    import { Input } from '$lib/components/ui/input';
    import { Textarea } from '$lib/components/ui/textarea';
    import * as Select from '$lib/components/ui/select';
    import { Label } from '$lib/components/ui/label';
    import { onMount, onDestroy } from 'svelte';

    import PointLight from '@/Icons/PointLight.svelte';
    import ForwardChevron from '@/Icons/ForwardChevron.svelte';
    import ChevronDownIcon from '@/Icons/ChevronDownIcon.svelte';

    export let show: boolean = false;
    export let user: { name: string; avatar: string; username: string };
    export let data: any;
    export let paymentSettings: any = {};
    export let authenticatedUser: any;
    export let userAvailableCredit: number;
    export let transactionType: string;
    export let paymentData: { amount: number; taxes: number; total: number } = {
        amount: 0,
        taxes: 0,
        total: 0,
    };

    let selectedPaymentMethod: string = '';
    let billingInfo: {
        firstName: string;
        lastName: string;
        country: string;
        city: string;
        state: string;
        postcode: string;
        billingAddress: string;
    } = {
        firstName: authenticatedUser?.first_name || '',
        lastName: authenticatedUser?.last_name || '',
        country: authenticatedUser?.country || '',
        city: authenticatedUser?.city || '',
        state: authenticatedUser?.state || '',
        postcode: authenticatedUser?.postcode || '',
        billingAddress: authenticatedUser?.billing_address || '',
    };

    let showBillingDetails: boolean = true;
    let countries: { id: string; name: string; taxes: any[] }[] = [];
    let formData: any = {};
    let errors: any = {};
    let isLoading: boolean = false;

    const paymentMethods: { id: string; name: string; logo?: string; condition: boolean }[] = [
        {
            id: 'stripe',
            name: 'Stripe',
            logo: '/img/logos/stripe.svg',
            condition:
                (paymentSettings?.stripeSecretKey || data.stripeSecretKey) &&
                (paymentSettings?.stripePublicKey || data.stripePublicKey) &&
                !(paymentSettings?.isStripeCheckoutDisabled || data.isStripeCheckoutDisabled),
        },
        {
            id: 'paypal',
            name: 'PayPal',
            logo: '/img/logos/paypal.svg',
            condition:
                (paymentSettings?.paypalClientId || data.paypalClientId) &&
                (paymentSettings?.paypalSecret || data.paypalSecret) &&
                !(paymentSettings?.isPaypalCheckoutDisabled || data.isPaypalCheckoutDisabled),
        },
        {
            id: 'coinbase',
            name: 'Coinbase',
            logo: '/img/logos/coinbase.svg',
            condition:
                (paymentSettings?.coinbaseApiKey || data.coinbaseApiKey) &&
                !(paymentSettings?.isCoinbaseCheckoutDisabled || data.isCoinbaseCheckoutDisabled),
        },
        {
            id: 'nowpayments',
            name: 'NowPayments',
            logo: '/img/logos/nowpayments.svg',
            condition:
                (paymentSettings?.nowPaymentApiKey || data.nowPaymentApiKey) &&
                !(paymentSettings?.isNowPaymentsCheckoutDisabled || data.isNowPaymentsCheckoutDisabled),
        },
        {
            id: 'ccbill',
            name: 'CCBill',
            logo: '/img/logos/ccbill.svg',
            condition: paymentSettings?.isCCbillCredentialsProvided || data.isCCbillCredentialsProvided,
        },
        {
            id: 'paystack',
            name: 'Paystack',
            logo: '/img/logos/paystack.svg',
            condition:
                (paymentSettings?.paystactSecretKey || data.paystactSecretKey) &&
                !(paymentSettings?.isPaystackCheckoutDisabled || data.isPaystackCheckoutDisabled),
        },
        {
            id: 'oxxo',
            name: 'OXXO',
            logo: '/img/logos/oxxo.svg',
            condition:
                (paymentSettings?.stripeSecretKey || data.stripeSecretKey) &&
                (paymentSettings?.stripePublicKey || data.stripePublicKey) &&
                !(paymentSettings?.isStripeCheckoutDisabled || data.isStripeCheckoutDisabled) &&
                (paymentSettings?.isStripeOXXOProviderEnabled || data.isStripeOXXOProviderEnabled),
        },
        { id: 'credit', name: 'Credit', condition: true },
    ];

    async function fetchCountries() {
        try {
            const response = await fetch('/countries');
            const result = await response.json();
            if (result && result.countries) {
                countries = result.countries.map((country: any) => ({
                    id: country.id,
                    name: country.name,
                    taxes: country.taxes,
                }));
            }
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    }

    function updateTaxes() {
        const selectedCountry = countries.find((country) => country.id === billingInfo.country);
        if (selectedCountry && selectedCountry.taxes) {
            paymentData.taxes = calculateTaxes(paymentData.amount, selectedCountry.taxes);
        } else {
            paymentData.taxes = 0;
        }
        updateTotal();
    }

    function calculateTaxes(amount: number, taxes: any[]): number {
        let totalTaxes = 0;
        if (Array.isArray(taxes)) {
            taxes.forEach((tax) => {
                if (tax.type === 'inclusive') {
                    totalTaxes += amount - amount / (1 + tax.percentage / 100);
                } else {
                    totalTaxes += (tax.percentage / 100) * amount;
                }
            });
        }
        return totalTaxes;
    }

    $: if (billingInfo.country) {
        updateTaxes();
    }

    function handleAmountChange(event: Event) {
        const input = event.target as HTMLInputElement;
        const amount = parseFloat(input.value);
        if (!isNaN(amount)) {
            paymentData.amount = amount;
            updatePaymentSummary();
        }
    }

    function handlePaymentMethodClick(methodId: string) {
        selectedPaymentMethod = methodId;
        errors.provider = '';
    }

    function handleModalShow(event: CustomEvent) {
        const {
            postId,
            recipientId,
            amount,
            type,
            username,
            firstName,
            lastName,
            billingAddress,
            name,
            avatar,
            country,
            city,
            state,
            postcode,
            availableCredit,
            streamId,
            userMessageId,
        } = event.detail;

        formData = {
            ...formData,
            transaction_type: type,
            post_id: postId,
            user_message_id: userMessageId,
            recipient_user_id: recipientId,
            amount: amount,
            first_name: firstName,
            last_name: lastName,
            billing_address: billingAddress,
            country: country,
            city: city,
            state: state,
            postcode: postcode,
            stream: streamId,
        };

        user = { name, avatar, username };
        userAvailableCredit = availableCredit;

        showBillingDetails = !firstName || !lastName || !billingAddress || !city || !state || !postcode || !country;

        paymentData.amount = amount;

        updatePaymentSummary();
    }

    function handleModalHide() {
        resetForm();
    }

    onMount(() => {
        fetchCountries();
    });

    $: if (show) {
        console.log('Dialog opened', { data, transactionType });

        if (transactionType === 'post-unlock') {
            paymentData.amount = Number(data.post?.price) || Number(data.price) || Number(data.amount) || 0;

            const postId = data.post?.id || data.id;

            formData = {
                transaction_type: transactionType,
                post_id: postId,
                recipient_user_id: data.user?.id || data.user_id,
                amount: paymentData.amount,
            };
        } else {
            formData = {
                transaction_type: transactionType,
                post_id: data.post?.id || '',
                recipient_user_id: data.user?.id || user.id,
                amount: paymentData.amount,
            };
        }

        console.log('Setting initial amount:', paymentData.amount, typeof paymentData.amount);
        console.log('Setting post_id:', formData.post_id);
        updatePaymentSummary();
    }

    function validateForm(): boolean {
        let isValid = true;

        if (transactionType === 'tip' && (paymentData.amount < 5 || paymentData.amount > 2000)) {
            errors.amount = 'Please enter a valid amount between 5 and 2000.';
            isValid = false;
        } else {
            errors.amount = '';
        }

        if (!billingInfo.firstName) {
            errors.firstName = 'First name is required.';
            isValid = false;
        } else {
            errors.firstName = '';
        }

        if (!billingInfo.lastName) {
            errors.lastName = 'Last name is required.';
            isValid = false;
        } else {
            errors.lastName = '';
        }

        if (!billingInfo.billingAddress) {
            errors.billingAddress = 'Billing address is required.';
            isValid = false;
        } else {
            errors.billingAddress = '';
        }

        if (!billingInfo.city) {
            errors.city = 'City is required.';
            isValid = false;
        } else {
            errors.city = '';
        }

        if (!billingInfo.state) {
            errors.state = 'State is required.';
            isValid = false;
        } else {
            errors.state = '';
        }

        if (!billingInfo.postcode) {
            errors.postcode = 'Postcode is required.';
            isValid = false;
        } else {
            errors.postcode = '';
        }

        if (!billingInfo.country) {
            errors.country = 'Country is required.';
            isValid = false;
        } else {
            errors.country = '';
        }

        return isValid;
    }

    async function handleFormSubmit() {
        console.log('Form submitted', { formData, selectedPaymentMethod, paymentData });

        if (!validateForm()) {
            console.log('Form validation failed', errors);
            return;
        }

        if (!selectedPaymentMethod) {
            errors.provider = 'Please select a payment method.';
            return;
        }

        const amount = Number(paymentData.amount);
        const availableCredit = Number(userAvailableCredit);

        if (selectedPaymentMethod === 'credit' && availableCredit < amount) {
            errors.provider = 'Insufficient credit balance.';
            return;
        }

        const postId = data.post?.id || data.id || '';

        formData = {
            amount: amount,
            transaction_type: transactionType,
            post_id: postId,
            user_message_id: '',
            recipient_user_id: data.user?.id || user.id,
            provider: selectedPaymentMethod,
            first_name: billingInfo.firstName,
            last_name: billingInfo.lastName,
            billing_address: billingInfo.billingAddress,
            city: billingInfo.city,
            state: billingInfo.state,
            postcode: billingInfo.postcode,
            country: billingInfo.country,
            taxes: paymentData.taxes,
            stream: '',
        };

        errors = {
            amount: '',
            firstName: '',
            lastName: '',
            billingAddress: '',
            city: '',
            state: '',
            postcode: '',
            country: '',
            provider: '',
        };

        console.log('Initiating payment', formData);
        await initiatePayment();
    }

    const token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '';

    async function initiatePayment() {
        try {
            const formData = new FormData();
            const postId = data.post?.id || data.id || '';
            const amount = Number(paymentData.amount);

            console.log('Setting post_id:', postId, 'amount:', amount);

            formData.append('_token', token);
            formData.append('amount', amount.toString());
            formData.append('transaction_type', transactionType);
            formData.append('post_id', postId.toString());

            const postField = document.querySelector('#post') as HTMLInputElement;
            if (postField) {
                postField.value = postId.toString();
            }

            formData.append('user_message_id', formData.user_message_id || '');
            formData.append('recipient_user_id', (data.user?.id || user.id || formData.recipient_user_id).toString());
            formData.append('provider', selectedPaymentMethod);
            formData.append('first_name', billingInfo.firstName);
            formData.append('last_name', billingInfo.lastName);
            formData.append('billing_address', billingInfo.billingAddress);
            formData.append('city', billingInfo.city);
            formData.append('state', billingInfo.state);
            formData.append('postcode', billingInfo.postcode);
            formData.append('country', billingInfo.country?.id || billingInfo.country); // Fix for country object
            formData.append(
                'taxes',
                JSON.stringify({
                    data: [],
                    taxesTotalAmount: '0.00',
                    subtotal: amount.toFixed(2),
                })
            );
            formData.append('stream', '');

            console.log('Sending payment request');
            const response = await fetch('/payment/initiate/validate', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                console.log('Payment validation successful:', result);

                const paymentForm = document.querySelector('#pp-buyItem') as HTMLFormElement;
                if (paymentForm) {
                    const fields = {
                        'payment-deposit-amount': amount.toString(),
                        'payment-type': transactionType,
                        post: postId,
                        recipient: (data.user?.id || user.id || formData.recipient_user_id).toString(),
                        provider: selectedPaymentMethod,
                        paymentFirstName: billingInfo.firstName,
                        paymentLastName: billingInfo.lastName,
                        paymentBillingAddress: billingInfo.billingAddress,
                        paymentCity: billingInfo.city,
                        paymentState: billingInfo.state,
                        paymentPostcode: billingInfo.postcode,
                        paymentCountry: billingInfo.country?.id || billingInfo.country,
                        paymentTaxes: JSON.stringify({
                            data: [],
                            taxesTotalAmount: '0.00',
                            subtotal: amount.toFixed(2),
                        }),
                    };

                    Object.entries(fields).forEach(([id, value]) => {
                        const field = paymentForm.querySelector(`#${id}`) as HTMLInputElement;
                        if (field) field.value = value;
                    });

                    console.log('Submitting final payment form');
                    paymentForm.submit();
                    return;
                }
            } else {
                const result = await response.json();
                console.error('Payment validation failed:', result);
                if (result.errors) {
                    Object.keys(result.errors).forEach((field) => {
                        errors[field] = result.errors[field][0];
                    });
                }
            }
        } catch (error) {
            console.error('Payment error:', error);
        }
    }

    function updateTotal() {
        paymentData.total = paymentData.amount + paymentData.taxes;
    }

    function updatePaymentSummary() {
        let subtotal = paymentData.amount;
        let taxes = calculateTaxes(subtotal, billingInfo.country);
        paymentData.taxes = taxes;
        paymentData.total = subtotal + taxes;
    }

    $: if (paymentData.amount || billingInfo.country) {
        updatePaymentSummary();
    }

    $: if (paymentData.amount) {
        updateTotal();
    }

    function resetForm() {
        paymentData.amount = 0;
        paymentData.taxes = 0;
        paymentData.total = 0;
        selectedPaymentMethod = '';
        billingInfo = {
            firstName: authenticatedUser?.first_name || '',
            lastName: authenticatedUser?.last_name || '',
            country: authenticatedUser?.country || '',
            city: authenticatedUser?.city || '',
            state: authenticatedUser?.state || '',
            postcode: authenticatedUser?.postcode || '',
            billingAddress: authenticatedUser?.billing_address || '',
        };
        errors = {
            amount: '',
            firstName: '',
            lastName: '',
            billingAddress: '',
            city: '',
            state: '',
            postcode: '',
            country: '',
            provider: '',
        };
    }

    $: if (!show) {
        resetForm();
    }

    onDestroy(() => {
        resetForm();
    });

    type TransactionType =
        | 'tip'
        | 'chat-tip'
        | 'one-month-subscription'
        | 'three-months-subscription'
        | 'six-months-subscription'
        | 'yearly-subscription'
        | 'post-unlock'
        | 'stream-access'
        | 'message-unlock';

    $: paymentDetails = (() => {
        switch (transactionType) {
            case 'tip':
            case 'chat-tip':
                return {
                    title: 'Send a tip',
                    description: 'Send a tip to this user',
                    showAmountInput: true,
                    providers: paymentMethods.filter((m) => m.condition),
                };
            case 'one-month-subscription':
            case 'three-months-subscription':
            case 'six-months-subscription':
            case 'yearly-subscription':
                const months = {
                    'one-month-subscription': 1,
                    'three-months-subscription': 3,
                    'six-months-subscription': 6,
                    'yearly-subscription': 12,
                }[transactionType];

                return {
                    title: transactionType,
                    description: `Subscribe to ${user.name} for ${months} ${months === 1 ? 'month' : 'months'}`,
                    showAmountInput: false,
                    providers: paymentMethods.filter((m) => {
                        if (m.id === 'ccbill' && months > 3) return false;
                        return m.condition && ['stripe', 'paypal', 'ccbill'].includes(m.id);
                    }),
                };
            case 'post-unlock':
                return {
                    title: 'Unlock post',
                    description: `Unlock post for ${paymentData.amount} points`,
                    showAmountInput: false,
                    providers: paymentMethods.filter((m) => m.condition),
                };
            case 'stream-access':
                return {
                    title: 'Join streaming',
                    description: `Join streaming now for ${paymentData.amount} points`,
                    showAmountInput: false,
                    providers: paymentMethods.filter((m) => m.condition),
                };
            case 'message-unlock':
                return {
                    title: 'Unlock message',
                    description: `Unlock message for ${paymentData.amount} points`,
                    showAmountInput: false,
                    providers: paymentMethods.filter((m) => m.condition),
                };
        }
    })();
</script>

<Dialog.Root bind:open="{show}">
    <Dialog.Content class="max-h-screen overflow-y-scroll dark:!border-neutral-800">
        <Dialog.Header>
            <Dialog.Title>{paymentDetails.title}</Dialog.Title>
        </Dialog.Header>

        <div class="space-y-4">
            <!-- User info -->
            <div class="flex space-x-4">
                <img src="{user.avatar}" alt="{user.name}" class="h-12 w-12 rounded-full" />
                <div class="items-center">
                    <div class="font-bold">{user.name}</div>
                    <p class="text-sm text-gray-500">@{user.username}</p>
                </div>
            </div>

            <!-- Amount input -->
            {#if paymentDetails.showAmountInput}
                <div class="space-y-2">
                    <Label for="amount">Amount</Label>
                    <Input
                        id="amount"
                        type="number"
                        bind:value="{paymentData.amount}"
                        min="5"
                        max="2000"
                        placeholder="Enter amount..."
                        on:input="{handleAmountChange}"
                        class="dark:!border-neutral-800"
                        disabled="{!paymentDetails.showAmountInput}"
                    />
                    {#if errors.amount}
                        <p class="text-sm text-red-500">{errors.amount}</p>
                    {/if}
                </div>
            {/if}

            <!-- Billing Information -->
            <div class="rounded-lg border p-4 dark:!border-neutral-800">
                <button
                    class="flex w-full items-center justify-between"
                    on:click="{() => (showBillingDetails = !showBillingDetails)}"
                >
                    <h6 class="font-bold">Billing Information</h6>
                    <span>
                        {#if showBillingDetails}
                            <ChevronDownIcon />
                        {:else}
                            <ForwardChevron />
                        {/if}
                    </span>
                </button>

                {#if showBillingDetails}
                    <div class="mt-4 grid grid-cols-2 gap-4">
                        <div class="space-y-2">
                            <Label for="firstName">First Name</Label>
                            <Input
                                id="firstName"
                                bind:value="{billingInfo.firstName}"
                                class="dark:!border-neutral-800"
                            />
                            {#if errors.firstName}
                                <p class="text-sm text-red-500">{errors.firstName}</p>
                            {/if}
                        </div>
                        <div class="space-y-2">
                            <Label for="lastName">Last Name</Label>
                            <Input id="lastName" bind:value="{billingInfo.lastName}" class="dark:!border-neutral-800" />
                            {#if errors.lastName}
                                <p class="text-sm text-red-500">{errors.lastName}</p>
                            {/if}
                        </div>
                        <div class="col-span-2 space-y-2">
                            <Label for="country">Country</Label>
                            <Select.Root
                                onSelectedChange="{(value) => {
                                    billingInfo.country = value;
                                    errors.country = '';
                                }}"
                            >
                                <Select.Trigger class="w-full dark:!border-neutral-800">
                                    <Select.Value placeholder="Select country">
                                        {countries.find((c) => c.id === billingInfo.country)?.name || 'Select country'}
                                    </Select.Value>
                                </Select.Trigger>
                                <Select.Content>
                                    {#each countries as country}
                                        <Select.Item value="{country.id}">{country.name}</Select.Item>
                                    {/each}
                                </Select.Content>
                            </Select.Root>
                            {#if errors.country}
                                <p class="text-sm text-red-500">{errors.country}</p>
                            {/if}
                        </div>
                        <div class="space-y-2">
                            <Label for="city">City</Label>
                            <Input id="city" bind:value="{billingInfo.city}" class="dark:!border-neutral-800" />
                            {#if errors.city}
                                <p class="text-sm text-red-500">{errors.city}</p>
                            {/if}
                        </div>
                        <div class="space-y-2">
                            <Label for="state">State</Label>
                            <Input id="state" bind:value="{billingInfo.state}" class="dark:!border-neutral-800" />
                            {#if errors.state}
                                <p class="text-sm text-red-500">{errors.state}</p>
                            {/if}
                        </div>
                        <div class="space-y-2">
                            <Label for="postcode">Postcode</Label>
                            <Input id="postcode" bind:value="{billingInfo.postcode}" class="dark:!border-neutral-800" />
                            {#if errors.postcode}
                                <p class="text-sm text-red-500">{errors.postcode}</p>
                            {/if}
                        </div>
                        <div class="col-span-2 space-y-2">
                            <Label for="billingAddress">Address</Label>
                            <Textarea
                                id="billingAddress"
                                bind:value="{billingInfo.billingAddress}"
                                rows="2"
                                class="dark:!border-neutral-800"
                            />
                            {#if errors.billingAddress}
                                <p class="text-sm text-red-500">{errors.billingAddress}</p>
                            {/if}
                        </div>
                    </div>
                {/if}
            </div>

            <!-- Payment Summary -->
            <div class="space-y-2">
                <h6 class="font-bold">Payment Summary</h6>
                <div class="flex justify-between">
                    <span>Subtotal:</span>
                    <span>{paymentData.amount}</span>
                </div>
                <div class="flex justify-between">
                    <span>Taxes:</span>
                    <span>{paymentData.taxes}</span>
                </div>
                <div class="flex justify-between font-bold">
                    <span>Total:</span>
                    <span>{paymentData.total}</span>
                </div>
            </div>

            <!-- Payment Methods -->
            <div class="space-y-2">
                <h6 class="font-bold">Payment Method</h6>
                <div class="grid grid-cols-3 gap-4">
                    {#each paymentDetails.providers as method}
                        <button
                            class="flex items-center justify-center rounded-lg border p-4 dark:!border-neutral-800"
                            class:border-primary="{selectedPaymentMethod === method.id}"
                            on:click="{() => handlePaymentMethodClick(method.id)}"
                        >
                            {#if method.logo}
                                <img src="{method.logo}" alt="{method.name}" class="h-8" />
                            {:else}
                                <div class="credit-provider-text flex gap-2">
                                    <b>{method.name}</b>
                                    <div class="available-credit flex items-center justify-between gap-1">
                                        <span>{userAvailableCredit}</span>
                                        <PointLight size="size-5" />
                                    </div>
                                </div>
                            {/if}
                        </button>
                    {/each}
                </div>
                {#if errors.provider}
                    <p class="text-sm text-red-500">{errors.provider}</p>
                {/if}
            </div>
        </div>

        <div class="mt-4 flex justify-end gap-2">
            <Button variant="outline" class="dark:!border-neutral-800" on:click="{() => (show = false)}">Cancel</Button>
            <Button on:click="{handleFormSubmit}" disabled="{isLoading}">
                Continue
                {#if isLoading}
                    <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                {/if}
            </Button>
        </div>
    </Dialog.Content>
</Dialog.Root>

<div class="paymentOption paymentPP d-none">
    <form id="pp-buyItem" method="post" action="/payment/initiate">
        <input type="hidden" name="_token" value="{token}" />
        <input type="hidden" name="amount" id="payment-deposit-amount" value="" />
        <input type="hidden" name="transaction_type" id="payment-type" value="" />
        <input type="hidden" name="post_id" id="post" value="" />
        <input type="hidden" name="user_message_id" id="userMessage" value="" />
        <input type="hidden" name="recipient_user_id" id="recipient" value="" />
        <input type="hidden" name="provider" id="provider" value="" />
        <input type="hidden" name="first_name" id="paymentFirstName" value="" />
        <input type="hidden" name="last_name" id="paymentLastName" value="" />
        <input type="hidden" name="billing_address" id="paymentBillingAddress" value="" />
        <input type="hidden" name="city" id="paymentCity" value="" />
        <input type="hidden" name="state" id="paymentState" value="" />
        <input type="hidden" name="postcode" id="paymentPostcode" value="" />
        <input type="hidden" name="country" id="paymentCountry" value="" />
        <input type="hidden" name="taxes" id="paymentTaxes" value="" />
        <input type="hidden" name="stream" id="stream" value="" />
        <button class="payment-button" type="submit"></button>
    </form>
</div>
